<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex ml-3 mb-2 align-items-center">
          <div class="mr-auto">
            <ul class="nav nav-pills">
              <li class="nav-item">
                <router-link to="/data-asset" class="nav-link"
                  >Aset</router-link
                >
              </li>
              <li class="nav-item">
                <router-link to="/data-asset-kategori" class="nav-link"
                  >Kategori Aset
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/data-asset-check" class="nav-link active"
                  >Pengecekan Aset</router-link
                >
              </li>
              <li class="nav-item">
                <router-link to="/data-asset-selling" class="nav-link"
                  >Penjualan Aset</router-link
                >
              </li>
            </ul>
          </div>

          <form class="search mr-3" @submit.prevent="onSearch">
            <input
              type="text"
              v-model="search"
              id="searchbox"
              @keyup="searchCheck"
              placeholder="Cari..."
              name="search"
            />
            <button type="submit"><i class="fa fa-search"></i></button>
          </form>

          <button
            @click="addCheckAsset"
            class="btn-add"
            v-if="
              checkAccess('asset') || checkAccess('asset', 'cru_check_asset')
            "
          >
            + Tambah
          </button>
        </div>
        <div class="card-table">
          <div class="table-responsive">
            <table
              class="table table-bordered table-striped table-hover"
              id="tableAsetCheck"
            >
              <thead>
                <tr>
                  <th style="width: 15%">Nomor</th>
                  <th style="width: 15%">Tanggal Perintah</th>
                  <th style="width: 15%">Gudang</th>
                  <th style="width: 20%">Penanggung Jawab</th>
                  <th style="width: 20%">Keterangan</th>
                  <th style="width: 15%">Status</th>
                  <th style="width: 10%" class="text-center">Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, index) in list" :key="index">
                  <td>{{ value.kode }}</td>
                  <td>{{ formatDate(value.tanggal_perintah) }}</td>
                  <td>{{ value.nama_gudang }}</td>
                  <td>{{ value.penanggung_jawab }}</td>
                  <td>{{ value.keterangan ? value.keterangan : "-" }}</td>
                  <td>
                    <div class="green-bedge" v-if="value.status == 'aktif'">
                      Aktif
                    </div>
                    <div class="red-bedge" v-else>Selesai</div>
                  </td>
                  <td>
                    <svg
                      v-on:click="detail(value.kode, value.status)"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Lihat Detail"
                      class="edit-icon"
                      width="27"
                      height="27"
                      viewBox="0 0 31 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.74935 2.58203V1.29036V2.58203ZM5.16602 5.16536H3.87435H5.16602ZM5.16602 25.832H3.87435H5.16602ZM18.0827 1.29036H7.74935V3.8737H18.0827V1.29036ZM7.74935 1.29036C6.72164 1.29036 5.73601 1.69862 5.00931 2.42533L6.836 4.25202C7.07824 4.00978 7.40678 3.8737 7.74935 3.8737V1.29036ZM5.00931 2.42533C4.28261 3.15203 3.87435 4.13765 3.87435 5.16536H6.45768C6.45768 4.82279 6.59377 4.49425 6.836 4.25202L5.00931 2.42533ZM3.87435 5.16536V25.832H6.45768V5.16536H3.87435ZM3.87435 25.832C3.87435 26.8597 4.28261 27.8454 5.00931 28.5721L6.836 26.7454C6.59377 26.5031 6.45768 26.1746 6.45768 25.832H3.87435ZM5.00931 28.5721C5.73601 29.2988 6.72163 29.707 7.74935 29.707V27.1237C7.40678 27.1237 7.07824 26.9876 6.836 26.7454L5.00931 28.5721ZM7.74935 29.707H23.2493V27.1237H7.74935V29.707ZM23.2493 29.707C24.2771 29.707 25.2627 29.2988 25.9894 28.5721L24.1627 26.7454C23.9205 26.9876 23.5919 27.1237 23.2493 27.1237V29.707ZM25.9894 28.5721C26.7161 27.8454 27.1243 26.8597 27.1243 25.832H24.541C24.541 26.1746 24.4049 26.5031 24.1627 26.7454L25.9894 28.5721ZM27.1243 25.832V5.16537H24.541V25.832H27.1243ZM23.2493 1.29036H18.0827V3.8737H23.2493V1.29036ZM27.1243 5.16537C27.1243 3.02526 25.3895 1.29036 23.2493 1.29036V3.8737C23.9627 3.8737 24.541 4.452 24.541 5.16537H27.1243Z"
                        fill="#898989"
                      />
                      <path
                        d="M21.9583 15.5H15.5"
                        stroke="#898989"
                        stroke-width="2.58333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.3327 15.5H9.04102"
                        stroke="#898989"
                        stroke-width="2.58333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M21.9583 21.957H15.5"
                        stroke="#898989"
                        stroke-width="2.58333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.3327 21.957H9.04102"
                        stroke="#898989"
                        stroke-width="2.58333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M21.9583 9.04297H18.7292H15.5"
                        stroke="#898989"
                        stroke-width="2.58333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.3327 9.04297H9.68685H9.04102"
                        stroke="#898989"
                        stroke-width="2.58333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../../components/Sidebar.vue";
import NavbarDashboard from "../../../../components/Navbar.vue";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";
import { cksClient, checkModuleAccess } from "../../../../helper";
import { get_AsetPengecekanList } from "../../../../actions/aset/pengecekan";
import { maska } from "maska";

import moment from "moment";
import "moment/locale/id";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    // TableLite
  },

  directives: {
    maska,
  },

  data() {
    return {
      checkAccess: checkModuleAccess,
      statusAset: [
        { id: "aktif", text: "Aktif" },
        { id: "tidak", text: "Nonaktif" },
      ],
      dataTable: "",
      list: [],
      search: "",
      isSearch: "",
      id_company: cksClient().get("_account").id_company,
    };
  },

  mounted() {
    this.tooltip();
    // this.dataTable = $("#tableAsetCheck").DataTable({
    //   info: false,
    // });
  },

  beforeUnmount() {
    if (this.dataTable) {
      this.dataTable.destroy();
      this.dataTable = "";
    }
  },

  created() {
    this.getList();
  },

  methods: {
    formatDate(string) {
      return moment(string).format("DD MMM YYYY");
    },

    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    detail(id, status) {
      $('[data-toggle="tooltip"]').tooltip("hide");
      if (status == "aktif") {
        this.$router.push({
          name: "EditAssetCheck",
          params: {
            kode: id,
          },
        });
      } else {
        this.$router.push({
          name: "DetailAssetCheck",
          params: {
            kode: id,
          },
        });
      }
    },

    searchCheck: function () {
      if (!this.search && this.isSearch) {
        this.isSearch = false;
        this.getList();
      }
    },
    async onSearch() {
      if (this.search || this.isSearch) {
        this.isSearch = true;
        this.getList();
      }
      if (!this.search && this.isSearch) {
        this.isSearch = false;
      }
    },

    addCheckAsset() {
      this.$router.push({
        name: "InsertAssetCheck",
      });
    },

    getList() {
      get_AsetPengecekanList(
        {
          id_company: this.id_company,
          search: this.search,
        },
        (res) => {
          const { list } = res;
          this.list = list;
          if (this.dataTable) {
            this.dataTable.destroy();
          }
          setTimeout(() => {
            this.dataTable = $("#tableAsetCheck").DataTable({
              info: false,
              language: {
                emptyTable: "Tidak ada data",
              },
              order: [[0, "desc"]],
            });
            this.tooltip();
          }, 500);
        }
      );
    },
  },
};
</script>

<style scoped>
.card-table {
  margin-top: 0px !important;
}
</style>

<style scoped src="../../../../assets/css/style.css"></style>

<style scoped src="../../../../assets/css/custom.css"></style>
